<ng-content />
<div
	[ngClass]="{
		'md:grid-cols-2': grid === 'cols-2',
		'md:grid-cols-3': grid === 'cols-3',
	}"
	class="grid grid-cols-1 gap-1"
	data-test-id="selection-card"
>
	@for (option of options; track option) {
		<label
			[for]="option.inputId"
			[ngClass]="{
				'cursor-pointer': !disabled,
				'control-selection-card-selected':
					option.inputId === selectedOption ||
					(option.selected && !selectedOption),
				'cursor-not-allowed opacity-50': disabled,
			}"
			class="control-selection-card"
			data-test-class="selection-card-option"
		>
			<svg-icon [src]="option.icon" class="icon-container" />
			<div class="flex flex-1 flex-col gap-1">
				<div class="font-semibold leading-4">
					{{ option.label | translate }}
				</div>
				<div class="leading-5">{{ option.byline | translate }}</div>
			</div>
			<input
				#input
				[attr.aria-describedby]="option.inputId"
				[attr.aria-labelledby]="option.inputId"
				[attr.data-test-id]="option.inputId"
				[checked]="option.selected"
				[disabled]="disabled"
				[id]="option.inputId"
				[name]="name"
				[ngClass]="{
					'!shadow-error-100': hasError && !disabled && !readonly,
					'!shadow-success-100': isValid && !disabled && !readonly,
				}"
				(change)="changedValue(option.inputId)"
				class="control-radio flex-none shrink-0 shadow-border shadow-ui-100"
				type="radio"
			/>
		</label>
	}
</div>
