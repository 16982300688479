import plugin from 'tailwindcss/plugin';

const inputPlugin = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const roundedBorder = theme('borderRadius.full');
		const rounded = theme('borderRadius.DEFAULT');

		const colorWhite = theme('colors.white');
		const colorUI25 = theme('colors.ui.25');
		const colorUI100 = theme('colors.ui.100');
		const colorUI125 = theme('colors.ui.125');
		const colorSelect100 = theme('colors.select.100');

		const twShadow = 'var(--tw-shadow-colored)';
		const twBoxShadow = '0px 0px 8px 0px var(--tw-shadow-color)';
		const boxShadow =
			'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)';

		const variables = {
			backgroundColor: `var(--inputs-color-background, ${colorUI25})`,
			backgroundColorActive: `var(--inputs-color-background-active, ${colorSelect100})`,
			borderColor: `var(--inputs-color-border, ${colorUI100})`,
			borderColorHover: `var(--inputs-color-border-hover, ${colorUI125})`,
			borderColorActive: `var(--inputs-color-background-active, ${colorSelect100})`,
			borderRadius: `var(--inputs-border-radius, ${rounded})`,
			borderWidth: `var(--inputs-border-width, ${theme('borderWidth.DEFAULT')})`,
			focusColor: `var(--inputs-color-focus, ${theme('colors.focus.100')})`,
			fontColor: `var(--inputs-color-text, ${theme('textColor.dark.primary')})`,
			fontColorPlaceholder: `var(--inputs-color-text-placeholder, ${theme('textColor.dark.quaternary')})`,
			fontSelected: `var(--inputs-color-text-active, ${colorWhite})`,
		};

		const basicInput = {
			appearance: 'none',
			backgroundColor: variables.backgroundColor,
			borderColor: variables.borderColor,
			borderRadius: variables.borderRadius,
			borderWidth: variables.borderWidth,
			color: variables.fontColor,
			fontWeight: theme('fontWeight.normal'),
			height: theme('spacing.6'),
			outline: '2px solid transparent',
			outlineOffset: '2px',
			width: theme('spacing.6'),
		};

		const basicInputDisabled = {
			cursor: 'not-allowed',
			opacity: 0.5,
		};

		const basicInputFocus = {
			'--tw-shadow-color': variables.focusColor,
			'--tw-shadow': twShadow,
			'--tw-shadow-colored': twBoxShadow,

			borderColor: variables.focusColor,
			boxShadow: boxShadow,
			outlineColor: 'transparent',
		};

		const basicInputHover = {
			borderColor: variables.borderColorHover,
		};

		const basicInputPlaceholder = {
			color: variables.fontColorPlaceholder,
			fontWeight: theme('fontWeight.normal'),
		};

		const basicInputError = {
			borderColor: `${theme('colors.error.100')} !important`,
		};

		addComponents({
			[`.${e('control-input')}`]: {
				...basicInput,
				paddingLeft: theme('spacing.3'),
				paddingRight: theme('spacing.3'),
				height: theme('height.11'),
				width: '100%',

				'&:disabled': {
					...basicInputDisabled,
				},

				'&.error': {
					...basicInputError,
				},

				'&:focus, &:focus-visible': {
					...basicInputFocus,
				},

				'&:hover': {
					...basicInputHover,
				},

				'&::placeholder': {
					...basicInputPlaceholder,
				},
			},
		});

		addComponents({
			[`.${e('control-radio')}`]: {
				...basicInput,
				borderRadius: roundedBorder,
				borderWidth: 0,
				cursor: 'pointer',
				width: theme('width.6'),

				'&:checked': {
					'--tw-shadow-color': variables.backgroundColorActive,
					'--tw-shadow': twShadow,

					backgroundColor: variables.backgroundColorActive,
					borderColor: colorWhite,
					borderWidth: '1px',
				},

				'&:disabled': {
					...basicInputDisabled,
				},

				'&:focus, &:focus-visible': {
					outlineWidth: '1px',
					outlineOffset: '4px',
					outlineColor: 'transparent',
				},
			},
		});

		addComponents({
			[`.${e('control-checkbox')}`]: {
				...basicInput,
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				borderRadius: rounded,
				borderWidth: theme('borderWidth.DEFAULT'),
				cursor: 'pointer',
				flex: 'none',
				verticalAlign: 'top',

				'&:checked': {
					backgroundColor: variables.backgroundColorActive,
					borderColor: variables.backgroundColorActive,

					'&:before': {
						backgroundColor: colorWhite,
						content: `var(--tw-content)`,
						display: 'block',
						height: theme('height.5'),
						marginLeft: '1px',
						maskImage:
							'url(https://assets.vandervalkonline.com/live/assets/icons/icon-checkmark.svg)',
						maskPosition: '50% 50%',
						maskRepeat: 'no-repeat',
						width: theme('width.5'),
					},
				},

				'&:disabled': {
					...basicInputDisabled,
				},

				'&:hover': {
					borderColor: variables.borderColorHover,
				},

				'&:focus, &:focus-visible': {
					...basicInputFocus,
				},
			},
		});

		addComponents({
			[`.${e('control-toggle')}`]: {
				...basicInput,
				backgroundColor: colorUI125,
				borderRadius: roundedBorder,
				cursor: 'pointer',
				height: theme('height.6'),
				width: theme('width.12'),

				'&:after': {
					backgroundColor: colorWhite,
					borderRadius: roundedBorder,
					content: `var(--tw-content)`,
					height: theme('height.5'),
					left: theme('spacing')[0.5],
					outlineColor: variables.borderColor,
					outlineStyle: 'solid',
					outlineWidth: '2px',
					position: 'absolute',
					top: theme('spacing')[0.5],
					width: theme('width.5'),
				},

				'&:disabled': {
					...basicInputDisabled,
				},
			},
			'.control-toggle-peer:checked ~ .control-toggle': {
				backgroundColor: variables.backgroundColorActive,

				'&:after': {
					'--tw-translate-x': ' 120%',

					content: 'var(--tw-content)',
					transform:
						'translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))',
				},
			},

			'.control-toggle-peer:disabled ~ .control-toggle': {
				cursor: 'not-allowed',
				opacity: 0.5,
			},

			'.control-toggle-peer:focus ~ .control-toggle': {
				'--tw-ring-color': 'rgb(0 0 0 / var(--tw-ring-opacity))',
				'--tw-ring-offset-width': '2px',
				'--tw-ring-offset-shadow':
					'var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)',
				'--tw-ring-opacity': '1',
				'--tw-ring-shadow':
					'var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
				boxShadow: `var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)`,
			},
		});

		addComponents({
			[`.${e('control-segmented')}`]: {
				borderRadius: variables.borderRadius,
				display: 'grid',
				gap: theme('spacing.1'),
				gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
				width: '100%',

				'&:checked': {
					borderColor: colorUI125,
				},

				[`@media (min-width: ${theme('screens.sm')})`]: {
					backgroundColor: colorUI25,
					borderColor: colorUI100,
					borderWidth: '1px',
					display: 'inline-flex',
					justifyContent: 'space-between',
					padding: theme('spacing.1'),

					'&:focus-within': {
						'--tw-shadow-color': variables.focusColor,
						'--tw-shadow': twShadow,
						'--tw-shadow-colored': twBoxShadow,
						borderColor: variables.backgroundColorActive,
						borderWidth: '1px',
						boxShadow,
					},
				},
			},

			[`.${e('control-segmented-item')}`]: {
				backgroundColor: colorUI25,
				borderColor: colorUI100,
				borderRadius: variables.borderRadius,
				borderWidth: '1px',
				padding: theme('spacing.1'),
				textAlign: 'center',

				'&:focus, &:focus-visible': {
					'--tw-shadow-color': variables.focusColor,
					'--tw-shadow': twShadow,
					'--tw-shadow-colored': twBoxShadow,
					borderColor: variables.checked,
					borderWidth: '1px',
					boxShadow,
				},

				[`@media (min-width: ${theme('screens.sm')})`]: {
					width: '100%',
					borderWidth: 0,
					backgroundColor: 'transparent',
					padding: 0,
				},
			},

			[`.${e('control-segmented-label')}`]: {
				borderRadius: variables.borderRadius,
				color: variables.fontColor,
				display: 'block',
				padding: `${theme('spacing')[1.5]} 0`,
				width: '100%',

				'&:hover': {
					backgroundColor: theme('colors.ui.75'),
				},
			},

			[`.${e('control-segmented-horizontal')}`]: {
				backgroundColor: colorUI25,
				borderColor: colorUI100,
				borderRadius: variables.borderRadius,
				borderWidth: '1px',
				display: 'inline-flex',
				gap: theme('spacing.1'),
				gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
				justifyContent: 'space-between',
				padding: theme('spacing.1'),
				width: '100%',

				'.control-segmented-item': {
					backgroundColor: 'transparent',
					borderRadius: variables.borderRadius,
					borderWidth: 0,
					padding: 0,
					textAlign: 'center',
					width: '100%',
				},
			},
			'.control-segmented-peer:checked ~ .control-segmented-label': {
				backgroundColor: variables.backgroundColorActive,
				color: variables.fontSelected,
				fontWeight: 'bold',
			},
			'.control-segmented-peer:focus ~ .control-segmented-label': {
				'--tw-shadow-color': variables.focusColor,
				'--tw-shadow': twShadow,
				'--tw-shadow-colored': twBoxShadow,

				boxShadow,
				outlineColor: variables.focusColor,
				outlineStyle: 'solid',
				outlineWidth: '1px',
			},
		});

		addComponents({
			[`.${e('control-textarea')}`]: {
				...basicInput,

				backgroundColor: colorUI25,
				borderRadius: variables.borderRadius,
				borderWidth: theme('borderWidth.DEFAULT'),
				height: 'auto',
				lineHeight: theme('lineHeight.none'),
				minHeight: '2.75rem',
				outline: '2px solid transparent',
				outlineOffset: '2px',
				padding: theme('spacing.3'),
				width: '100%',

				'&:disabled': {
					...basicInputDisabled,
				},

				'&.error': {
					...basicInputError,
				},

				'&:focus, &:focus-visible': {
					'--tw-shadow-color': variables.focusColor,
					'--tw-shadow': twShadow,
					'--tw-shadow-colored': twBoxShadow,

					borderColor: variables.backgroundColorActive,
					borderWidth: '1px',
					boxShadow,
				},
			},
		});
		addComponents({
			[`.${e('control-selection-card')}`]: {
				borderColor: variables.borderColor,
				borderRadius: variables.borderRadius,
				borderWidth: variables.borderWidth,
				paddingLeft: theme('spacing.3'),
				paddingRight: theme('spacing.3'),
				paddingTop: theme('spacing.2'),
				paddingBottom: theme('spacing.2'),
				display: 'flex',
				alignItems: 'center',
				gap: theme('spacing.2'),

				'&.control-selection-card-selected': {
					borderColor: variables.borderColorActive,
				},
			},
		});
	};
});

export default inputPlugin;
