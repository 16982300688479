<ng-container *ngrxLet="isMobile$ as isMobile"
	><div (clickOutside)="showTooltip && hide()" class="flex" vc-click-outside>
		<button
			[attr.aria-describedby]="tooltipContentId"
			[attr.aria-expanded]="showTooltip"
			[attr.aria-label]="
				ariaLabel || 'global.more-information' | translate
			"
			[id]="tooltipTriggerId"
			(click)="toggle()"
			type="button"
		>
			<div #ref>
				<ng-content select="vp-tooltip-trigger" />
			</div>
			@if (ref.children.length === 0) {
				<svg-icon
					[class]="iconSize"
					[ngClass]="tooltipColor"
					class="pointer-events-none block h-auto cursor-pointer"
					data-test-id="tooltip-trigger"
					src="assets/icons/icon-info-circle-solid.svg"
				/>
			}
		</button>
		<div
			[id]="tooltipContentId"
			[ngClass]="[
				showTooltip ? 'visible opacity-100' : 'invisible opacity-0',
				isMobile
					? '!fixed !bottom-0 !left-0 !top-auto h-fit !w-full !transform-none'
					: 'animate-fade-in transition-opacity duration-500',
			]"
			class="tooltip z-30 h-full w-fit sm:h-auto sm:max-h-32 sm:max-w-xs"
			role="tooltip"
		>
			<div
				[class]="
					'card absolute bottom-0 h-fit !max-h-[calc(100vh-180px)] w-full overflow-y-auto !rounded-none !shadow-none sm:static sm:h-full sm:!max-h-32 sm:!rounded' +
					(isMobile ? ' transition-sheet duration-500' : '') +
					(showTooltip ? '' : ' -bottom-[1000px]')
				"
			>
				@if (isMobile) {
					<div class="mb-2 flex justify-end">
						<span (click)="toggle()" class="text-link">
							{{ 'global.close' | translate }}
						</span>
					</div>
				}
				<div
					class="text-dark-primary sm:relative sm:z-10"
					data-test-id="tooltip-content"
				>
					<ng-content select="vp-tooltip-content" />
				</div>
				<div [id]="tooltipArrowId" class="arrow-container">
					<div class="arrow hidden sm:block"></div>
				</div>
			</div>
		</div>
	</div>
	<div
		[ngClass]="{
			'overlay-dark-3 fixed left-0 top-0 z-20 size-full':
				showTooltip && isMobile,
		}"
		class="transition-colors"
	></div>
</ng-container>
