/* istanbul ignore next */

import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BehaviorSubject } from 'rxjs';

import buildInfo from '@valk-nx/build-info.json';

export const languages = ['nl', 'ca', 'en', 'es', 'de', 'fr'] as const;
export const ASSETS_ROOT = new InjectionToken<string>('valk-core');
export const HOTEL_SLUG = new InjectionToken<string[]>('hotel-slug');

/* istanbul ignore next */
export function createTranslateLoader(
	http: HttpClient,
	assetsRoot: string,
): TranslateHttpLoader {
	return new TranslateHttpLoader(
		http,
		`${assetsRoot}/i18n/`,
		`.json?v=${buildInfo?.version || 1}`,
	);
}

export const fallbackLanguage = languages[0];
export type Language = (typeof languages)[number];
export type LanguageRecord = Record<Language, string>;

export const languagesLabels: LanguageRecord = {
	ca: 'Catalunya',
	de: 'Deutsch',
	en: 'English',
	es: 'Español',
	fr: 'Français',
	nl: 'Nederlands',
};
export const minimumSpendableLoyalAmount = 250;

export type LinkTarget = '_blank' | '_self' | '';

export const negativeHeader$: BehaviorSubject<boolean> =
	new BehaviorSubject<boolean>(true);

export const LOADING = 'loading';
export const SUCCESS = 'success';
export const FAILED = 'failed';
export const CANCELLED = 'cancelled';
export const UNAUTHORIZED = 'unauthorized';
export const FORBIDDEN = 'forbidden';
