<div
	[class]="className"
	class="flex flex-col gap-2 px-2 sm:px-4 md:gap-4"
	data-test-id="status-header"
>
	@if (icon) {
		<div class="flex justify-center" data-test-id="status-header-icon">
			<svg-icon
				[attr.data-test-id]="icon"
				[class]="iconAnimationClass"
				[ngClass]="{
					'icon-container-success': iconType === iconTypes.success,
					'icon-container-warning': iconType === iconTypes.warning,
					'icon-container-error': iconType === iconTypes.error,
					'icon-container-info': iconType === iconTypes.default
				}"
				[src]="icon"
				[svgAriaLabel]="ariaLabel"
				class="icon-container bg-white md:icon-container-lg"
			/>
		</div>
	}
	<div class="flex flex-col gap-2">
		<div
			class="text-center children:mb-0 children:text-xl children:font-semibold children:sm:text-2xl"
			data-test-id="status-header-title"
		>
			<ng-content select="[vp-status-header-title]" />
		</div>
		<div
			class="mx-auto max-w-xl text-center children:mb-0 children:text-base children:text-dark-primary children:sm:text-lg"
			data-test-id="status-header-content"
		>
			<ng-content select="[vp-status-header-content]" />
		</div>
	</div>
</div>
