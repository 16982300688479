import plugin from 'tailwindcss/plugin';

const loyalCardPlugin = plugin.withOptions(function () {
	return function ({ addComponents, _theme, e }) {
		const loyalCardBackgroundImage = `url('/assets/icons/illustration-leaves.svg')`;

		addComponents({
			[`.${e('loyal-card')}`]: {
				position: 'relative',

				'&:before': {
					position: 'absolute',
					backgroundImage: loyalCardBackgroundImage,
					backgroundRepeat: 'no-repeat',
					content: '""',
					inset: 0,
					maskImage:
						'linear-gradient(to bottom, rgb(255 255 255 / 0%), rgb(255 255 255 / 50%))',
					objectFit: 'contain',
				},
			},
		});
	};
});

export default loyalCardPlugin;
